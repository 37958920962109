

























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'
import Icons from '@bertazzoni/common/src/components/Icons.vue'

@Component({
  components: { Icons }
})
export default class DatePicker extends Vue {
  @Prop({ default: null }) date!: string
  @Prop({ default: null }) minDate!: string
  @Prop({ default: null }) maxDate!: string
  @Prop({ default: '' }) placeHolder!: string
  @Prop({ default: false }) readonly!: boolean
  @Prop({ default: false }) required!: boolean
  private DAYS = moment.weekdaysShort()
  private focus = { input: false, calendar: false }
  private formatedDate: Date = this.date ? new Date(this.date) : null
  private selectedDate: Date = new Date()
  private calendarMonth = null
  private calendarYear = null
  private noOfDays = []
  private blankdays = []
  created(): void {
    if (this.date && this.date !== '') {
      this.calendarMonth = moment(this.formatedDate).month()
      this.calendarYear = moment(this.formatedDate).year()
      this.selectedDate = new Date(this.date)
    } else {
      this.calendarMonth = moment().month()
      this.calendarYear = moment().year()
      this.selectedDate = null
      this.formatedDate = null
    }
    this.getNoOfDays()
  }
  isSelectedDay(date: number): boolean {
    const d = new Date(this.calendarYear, this.calendarMonth, date)
    return this.selectedDateString === d.toDateString() ? true : false
  }
  isBeforeMinDate(date: number): boolean {
    if (this.minDate) {
      const d = moment({ year: this.calendarYear, month: this.calendarMonth, day: date })
      return moment(this.minDate).isAfter(d) ? true : false
    } else {
      return false
    }
  }
  isAfterMaxDate(date: number): boolean {
    if (this.maxDate) {
      const d = moment({ year: this.calendarYear, month: this.calendarMonth, day: date })
      return moment(this.maxDate).isBefore(d) ? true : false
    } else {
      return false
    }
  }
  getDateValue(date: number): void {
    this.selectedDate = new Date(this.calendarYear, this.calendarMonth, date)
    //Deselect a day
    if (!this.required) {
      this.formatedDate &&
      this.selectedDate &&
      this.formatedDate.toDateString() === this.selectedDate.toDateString()
        ? ((this.formatedDate = null), (this.selectedDate = null))
        : (this.formatedDate = this.selectedDate)
    }
    this.focus.input = false
    this.focus.calendar = false
    this.$emit('new-date', this.selectedDate)
  }
  changeMonth(way: string) {
    way === 'previous' ? this.nextMonth() : this.previousMonth()
    this.getNoOfDays()
  }
  nextMonth(): void {
    this.calendarMonth--
    if (this.calendarMonth === -1) {
      this.calendarYear--
      this.calendarMonth = 11
    }
  }
  previousMonth(): void {
    this.calendarMonth++
    if (this.calendarMonth === 12) {
      this.calendarYear++
      this.calendarMonth = 0
    }
  }
  get monthString(): string {
    return moment()
      .month(this.calendarMonth)
      .format('MMMM')
  }
  get selectedDateString(): string {
    return this.formatedDate ? this.selectedDate.toDateString() : ''
  }
  getNoOfDays(): void {
    const daysInMonth = new Date(this.calendarYear, this.calendarMonth + 1, 0).getDate()
    // find where to start calendar day of week
    const dayOfWeek = new Date(this.calendarYear, this.calendarMonth).getDay()
    const blankdaysArray = []
    for (let i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i)
    }
    const daysArray = []
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i)
    }
    this.blankdays = blankdaysArray
    this.noOfDays = daysArray
  }

  @Watch('date')
  dateChange(newValue: string): void {
    if (newValue && newValue !== '') {
      this.calendarMonth = moment(this.formatedDate).month()
      this.calendarYear = moment(this.formatedDate).year()
      this.selectedDate = new Date(newValue)
    } else {
      this.calendarMonth = moment().month()
      this.calendarYear = moment().year()
      this.selectedDate = null
      this.formatedDate = null
    }
  }
}
